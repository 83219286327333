import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header";

const BookmarkPage = () => {
  const [bookmarkedDocs, setBookmarkedDocs] = useState([]);
  const [error, setError] = useState("");
  const location = useLocation();
  const dataReceived = location.state;

  useEffect(() => {
    if (Array.isArray(dataReceived)) {
  
      const filteredData = dataReceived.filter(item => item.isbookmarked === true);
  
  
      setBookmarkedDocs(filteredData);
    } else {
      console.warn("dataReceived is not an array:", dataReceived);
      setBookmarkedDocs([]);
    }
  }, [dataReceived]);
  

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-red-600 text-xl font-semibold">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300">
      <Header />
      <div className="container mx-auto px-6 py-8">
        <h1 className="text-3xl font-bold mb-8 ">
          Bookmarked Documents
        </h1>
        {bookmarkedDocs.length === 0 ? (
          <div className="text-center mt-16">
            <p className="text-gray-600 text-lg">No bookmarked documents found.</p>
          </div>
        ) : (
          <div className="relative overflow-hidden border rounded-lg shadow-lg bg-white">
            <div className="overflow-y-auto" style={{ maxHeight: "1000px" }}>
              <table className="table-auto w-full border-collapse border border-gray-200">
                <thead className="sticky top-0 bg-gray-800 text-white">
                  <tr>
                    <th className="px-6 py-4 text-left font-semibold">File Name</th>
                    <th className="px-6 py-4 text-left font-semibold">Category</th>
                    <th className="px-6 py-4 text-center font-semibold">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bookmarkedDocs.map((doc, index) => (
                    <tr
                      key={doc.id}
                      className={`${
                        index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"
                      } hover:bg-gray-200`}
                    >
                      <td className="px-6 py-4 text-gray-800 font-medium">{doc.fileName}</td>
                      <td className="px-6 py-4 text-gray-800">{doc.category}</td>
                      <td className="px-6 py-4 text-center">
                        <a
                          href={doc.downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 transition"
                        >
                          Open
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookmarkPage;
