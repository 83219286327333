import React, { useContext, useEffect, useState } from "react";

export const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uid, setUid] = useState(null)

    const login = (data) => {
        // Store the user information in session storage
        sessionStorage.setItem('user', data.userId);
        sessionStorage.setItem('name', data.userName);
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('lastUpdate', data.lastUpdated);
        sessionStorage.setItem('accessToken', data.accessToken);
        sessionStorage.setItem('refreshToken', data.refreshToken);

        // Set the current user to the context
        setCurrentUser(data.accessToken);
        setUid(data.userId)
        setLoading(false);
    };

    const logout = () => {
        // Clear session storage and update context
        sessionStorage.clear();
        setUid(null);
        setCurrentUser(null);
    };

    useEffect(() => {
        const accessToken = sessionStorage.getItem('accessToken');
        const uid = sessionStorage.getItem('user');
        if (accessToken) {
            setCurrentUser(accessToken);
            setUid(uid)
        }
        setLoading(false);
    }, []);

    const value = {
        currentUser,
        login,
        logout,
        uid
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
