import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Header from "../components/header";
import app from "../app.json";

const Remainders = () => {
  const { uid } = useContext(AuthContext); // Get user details from context
  const [documents, setDocuments] = useState([]); // State to store fetched documents
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchDueDocuments = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(`${app.API_URL}/due-documents`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid }), // Pass `uid` in the request body
        });

        if (!response.ok) {
          throw new Error("Failed to fetch documents");
        }

        const data = await response.json();
        setDocuments(data); // Update state with fetched documents
      } catch (err) {
        console.error("Error fetching expiring documents:", err);
        setError("Failed to load documents.");
      } finally {
        setLoading(false);
      }
    };

    if (uid) {
      fetchDueDocuments(); // Fetch only if `uid` is available
    }
  }, [uid]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300">
      <Header />
      <div className="container mx-auto px-6 py-8">
        <h1 className="text-3xl font-bold mb-8">
          Reminders
        </h1>
        {loading ? (
          <div className="text-center">
            <p className="text-gray-600 text-lg">Loading...</p>
          </div>
        ) : error ? (
          <div className="text-center">
            <p className="text-red-500 text-lg">{error}</p>
          </div>
        ) : documents.length === 0 ? (
          <div className="text-center">
            <p className="text-gray-600 text-lg">No upcoming reminders.</p>
          </div>
        ) : (
          <div className="relative overflow-hidden border rounded-lg shadow-lg bg-white">
            <div className="overflow-y-auto" style={{ maxHeight: "1000px" }}>
              <table className="table-auto w-full border-collapse border border-gray-200">
                <thead className="sticky top-0 bg-gray-800 text-white">
                  <tr>
                    <th className="px-6 py-4 text-left font-semibold">File Name</th>
                    <th className="px-6 py-4 text-left font-semibold">Category</th>
                    <th className="px-6 py-4 text-left font-semibold">
                      Expiration Date
                    </th>
                    <th className="px-6 py-4 text-left font-semibold">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((doc, index) => (
                    <tr
                      key={doc.id}
                      className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"
                        } hover:bg-gray-200`}
                    >
                      <td className="px-6 py-4 text-gray-800 font-medium">{doc.file_name}</td>
                      <td className="px-6 py-4 text-gray-800">{doc.category}</td>
                      <td className="px-6 py-4 text-gray-800">
                        {new Date(doc.expiration_date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 text-gray-800">{doc.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Remainders;
