import React from 'react';
import { useNavigate } from 'react-router-dom';

const AddDocPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>Add Document Page</h1>
      <button onClick={() => navigate('/home')}>Back to Home</button>
    </div>
  );
};

export default AddDocPage;
