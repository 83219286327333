import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import app from '../app.json';

const MyDocs = () => {
  const [categories, setCategories] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [file, setFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser, uid } = useContext(AuthContext);
  const [fileName, setFileName] = useState('');
  const [fileDate, setFileDate] = useState(new Date().toDateString());
  const [notificationFrequency, setNotificationFrequency] = useState(360);
  const navigate = useNavigate();
  const frequencyOptions = [
    { label: "Remind me before 1 Week", value: 7 },
    { label: "Remind me before 2 Weeks", value: 14 },
    { label: "Remind me before 3 Weeks", value: 21 },
    { label: "Remind me before 1 Month", value: 30 },
    { label: "Remind me before 3 Months", value: 90 },
    { label: "Remind me before 6 Months", value: 180 },
    { label: "Remind me before 1 Year", value: 365 }
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${app.API_URL}/get-doc-categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching document categories:', error);
      }
    };

    const fetchDocuments = async () => {
      try {
        const response = await axios.post(`${app.API_URL}/documents`, {
          uid: uid,
        });
        setRecentDocuments(response.data.documents);
        setFilteredDocuments(response.data.documents);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchCategories();
    fetchDocuments();
  }, [uid]);


  const filterDocumentsByCategory = (category) => {
    if (category) {
      const filtered = recentDocuments.filter((doc) => doc.category === category);
      setFilteredDocuments(filtered);
      console.log(filteredDocuments)
    } else {
      setFilteredDocuments(recentDocuments);
    }
  };

  const handleBookmark = async (fileId, isBookmarked) => {
    try {
      const response = await axios.put(`${app.API_URL}/updateBookmark`, {
        fileId,
        isBookmarked: !isBookmarked,
        uid: uid,
      });
      setFilteredDocuments((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === fileId ? { ...doc, isbookmarked: !isBookmarked } : doc
        )
      );
      setRecentDocuments((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === fileId ? { ...doc, isbookmarked: !isBookmarked } : doc
        )
      );
    } catch (error) {
      console.error('Error updating bookmark status:', error);
      alert('Failed to update bookmark status.');
    }
  };

  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory('');
      filterDocumentsByCategory('');
    } else {
      setSelectedCategory(category);
      filterDocumentsByCategory(category);
    }
  };


  const handleDeleteDocument = async (documentId) => {
    if (!documentId) {
      alert('Document ID is missing.');
      return;
    }

    try {
      // console.log(currentUser.uid,documentId,"user and docdi")
      const response = await axios.post(`${app.API_URL}/delete-doc`, {
        documentId: documentId,
        uid: uid
      });

      if (response.status === 200) {
        alert('Document deleted successfully.');
        setFilteredDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.id !== documentId)
        );
        setRecentDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.id !== documentId)
        );
      }
    } catch (error) {
      console.error('Error deleting document:', error.message);
      alert('Failed to delete the document.');
    }
  };


  const handleFileUpload = async () => {
    if (!file || !selectedCategory || !fileName) {
      alert('Please fill all fields: Name, category & Select File');
      return;
    }

    try {
      const response = await axios.post(`${app.API_URL}/upload-doc`, {
        fileName: fileName, // Use the custom file name
        category: selectedCategory,
        user_givendate: fileDate,
        uid: uid,
        notificationFrequency: notificationFrequency

        // Send the selected date
      });

      await axios.put(response.data.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      alert('File uploaded successfully!');
      setShowUploadPopup(false);
      setFile(null);
      setFileName(''); // Reset file name input
      setFileDate(''); // Reset file date input
      setSelectedCategory('');
      filterDocumentsByCategory(selectedCategory);

      window.location.reload(); // Auto-refresh after upload
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload the file.');
    }
  };



  // New search function
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    // Filter documents based on selected category and search term
    const documentsToSearch = selectedCategory
      ? recentDocuments.filter((doc) => doc.category === selectedCategory)
      : recentDocuments;

    const filtered = documentsToSearch.filter((doc) =>
      doc.fileName.toLowerCase().includes(searchTerm)
    );

    setFilteredDocuments(filtered);
  };

  // Delete document function


  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 p-4">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">My Documents</h1>
          <button
            onClick={() => setShowUploadPopup(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
          >
            Upload Documents
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search documents..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full px-4 py-2 border rounded-md"
          />

        </div>
        <div className="flex justify-between gap-5">
          <button
            onClick={() => navigate('/bookmarks', { state: recentDocuments })}
            className="mt-2 mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Bookmarks
          </button>
          <button
            onClick={() => navigate('/remainders')}
            className="mt-2 mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Reminders
          </button>
        </div>

        {/* Categories Section */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
          {categories.map((category) => (
            <div
              key={category.id}
              onClick={() => handleCategoryClick(category.cat_name)}
              className={`flex flex-col items-center bg-white shadow-md rounded-lg p-4 cursor-pointer hover:shadow-lg transition ${selectedCategory === category.cat_name ? 'border-2 border-blue-600' : ''
                }`}
            >
              <img
                src={category.cat_img}
                alt={category.cat_name}
                className="w-16 h-16 object-contain mb-2"
              />
              <p className="text-lg font-medium">{category.cat_name}</p>
              {selectedCategory === category.cat_name && (
                <span className="text-green-600 mt-2">&#10003; Selected</span>
              )}
            </div>
          ))}
        </div>

        {/* Recent Documents Section */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4">
            {selectedCategory ? `${selectedCategory} Documents` : 'Recent Documents'}
          </h2>
          {Array.isArray(filteredDocuments) && filteredDocuments.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              <ul className="divide-y divide-gray-200">
                {filteredDocuments.map((doc) => {

                  return (
                    <li
                      key={doc.id}
                      className="flex justify-between items-center py-3"
                    >
                      <p className="text-lg font-medium text-gray-700 ml-4">{doc.fileName}</p>
                      <div className="flex">
                        <button
                          onClick={() => handleBookmark(doc.id, doc.isbookmarked)}
                          className="mr-4 text-lg hover:underline"
                        >
                          {doc.isbookmarked ? '⭐' : '☆'}
                        </button>

                        <a
                          href={doc.downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mr-4 text-blue-600 text-lg hover:underline"
                        >
                          Open
                        </a>

                        <button
                          onClick={() => handleDeleteDocument(doc.id)} // Check if doc.id exists
                          className="text-red-600 text-lg hover:underline"
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  );
                })}

              </ul>
            </div>
          ) : (
            <p className="text-gray-500">
              No documents available in {selectedCategory || 'recent'}
            </p>
          )}
        </div>

        {/* Upload Document Popup */}
        {showUploadPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Upload Document</h2>

              {/* Custom File Name Input */}
              <label className="block mb-4">
                File Name:
                <input
                  type="text"
                  value={fileName} // Add a fileName state to manage this input
                  onChange={(e) => setFileName(e.target.value)}
                  placeholder="Enter custom file name"
                  className="block w-full mt-1 p-2 border rounded"
                />
              </label>

              {/* Date Input */}
              <label className="block mb-4">
                Due Date: (Optional)
                <input
                  type="date"
                  value={fileDate} // Add a fileDate state to manage the date input
                  onChange={(e) => setFileDate(e.target.value)}
                  className="block w-full mt-1 p-2 border rounded"
                />
              </label>

              {/* Category Select */}
              <label className="block mb-2">
                Select Category:
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="block w-full mt-1 p-2 border rounded"
                >
                  <option value="">Select a Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.cat_name}>
                      {category.cat_name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="block mb-2">
                Notification Frequency: (Optional)
                <select
                  value={notificationFrequency}
                  onChange={(e) => setNotificationFrequency(e.target.value)}
                  className="block w-full mt-1 p-2 border rounded"
                >
                  <option value="">Set Notification Frequency</option>
                  {frequencyOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </label>


              {/* File Input */}
              <label className="block mb-4">
                Select File:
                <input
                  type="file"
                  accept=".pdf, .docx, .xls, .jpg, .jpeg, .png"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const allowedTypes = [
                      "application/pdf",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/vnd.ms-excel",
                      "image/jpeg",
                      "image/png"
                    ];

                    if (file && allowedTypes.includes(file.type)) {
                      setFile(file);
                    } else {
                      alert("File type not supported. Please upload a PDF, DOCX, XLS, JPG, or PNG file.");
                      e.target.value = "";
                    }
                  }}
                  className="block w-full mt-1 p-2 border rounded"
                />
              </label>

              {/* Actions */}
              <div className="flex justify-end">
                <button
                  onClick={() => setShowUploadPopup(false)}
                  className="bg-gray-600 text-white px-4 py-2 rounded-md mr-2 hover:bg-gray-700 transition"
                >
                  Cancel
                </button>
                <button
                  onClick={handleFileUpload}
                  className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default MyDocs;
