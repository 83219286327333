import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import app from '../app.json';

const Dashboard = () => {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      if (currentUser) {
        await fetch(`${app.auth_service}/auth/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessToken: currentUser }),
        });
      }
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Fetch dashboard options from the API
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(`${app.API_URL}/dash-opts`, {
          headers: {
            Authorization: `Bearer ${app.JWT_AUTH}`,
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) throw new Error("Failed to fetch options");
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching dashboard options:', error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <div className="flex justify-between items-center p-4 bg-white shadow-md">
        <div>
          <h1 className="text-3xl font-bold">Hello,</h1>
          <p className="text-lg">
            Welcome to <span className="text-yellow-500 font-bold">Health Of Wealth (HoW)</span> by Echowin Wealth
          </p>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="p-2 bg-red-600 rounded-full text-white">
            <i className="fi fi-ss-sign-out-alt"></i> Logout
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center mt-12 gap-4">
        {options.map((option) => (
          <div
            key={option.id}
            className={`flex items-center justify-center w-11/12 md:w-1/2 lg:w-1/3 ${
              option.name === 'My Docs' ? 'bg-blue-800' : 'bg-gray-400'
            } text-white text-lg rounded-lg shadow-lg p-4 gap-4 transition-all duration-200 ${
              option.name === 'My Docs' ? 'cursor-pointer hover:bg-blue-700' : 'cursor-not-allowed'
            }`}
            onClick={() => {
              if (option.name === 'My Docs') {
                navigate('/myDocs');
              }
            }}
          >
            <img
              src={option.icon_url}
              alt={option.name}
              className="w-32 h-32 object-contain"
            />
            <p className="font-medium">{option.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
