import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import app from '../app.json';
import { useAuth } from "../context/AuthContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    if (username && password) {
      try {
        setLoginLoading(true);
        const response = await fetch(`${app.auth_service}/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        setLoginLoading(false);

        if (response.ok) {
          login(data);
          const verifyResponse = await fetch(`${app.API_URL}/verify-user`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${data.accessToken}`,
            },
            body: JSON.stringify({ uid: data.userId }),
          });

          if (verifyResponse.ok) {
            navigate('/dashboard');
          } else {
            setError('User verification failed.');
          }
        } else {
          setError(data.error || 'Login failed');
        }
      } catch (error) {
        setLoginLoading(false);
        setError('Login request failed');
        console.error(error);
      }
    } else {
      setError('Please enter Username and Password');
    }
  };

  const handleRegister = () => {
    navigate('/registerUser');
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center overflow-hidden">
      <div className="flex flex-col items-center w-full max-w-md px-6 py-8 mx-auto">
        <a href="#" className="flex items-center text-3xl font-semibold text-gray-900 dark:text-white mb-4">
          <img
            className="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          Echowin Wealth Trail
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6">
            <h1 className="text-xl text-center font-semibold text-gray-900 md:text-2xl dark:text-white">
              Sign In
            </h1>
            <Carousel autoPlay infiniteLoop showThumbs={false} className="mb-4">
              <div className="relative h-64">
                <img src="https://blog.shoonya.com/wp-content/uploads/2022/12/Mutual-Funds_-Everything-You-Should-Know.jpg" alt="Slide 1" className="object-cover w-full h-full" />
              </div>
              <div className="relative h-64">
                <img src="https://blog.shoonya.com/wp-content/uploads/2022/12/Mutual-Funds_-Everything-You-Should-Know.jpg" alt="Slide 2" className="object-cover w-full h-full" />
              </div>
              <div className="relative h-64">
                <img src="https://blog.shoonya.com/wp-content/uploads/2022/12/Mutual-Funds_-Everything-You-Should-Know.jpg" alt="Slide 3" className="object-cover w-full h-full" />
              </div>
            </Carousel>

            <div>
              <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter username"
              />
            </div>

            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter password"
              />
            </div>

            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}

            <button
              onClick={handleLogin}
              className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500"
              disabled={loginLoading}
            >
              {loginLoading ? "Logging in..." : "Sign In"}
            </button>

            <button
              onClick={handleRegister}
              className="w-full mt-4 rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-gray-500"
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
