
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/dashboard';
import AddDocPage from './pages/AddDocPage';
import LoginPage from './pages/LoginPage';
import { useAuth } from './context/AuthContext';
import MyDocs from './pages/myDocs';
import RegisterPage from './pages/registerUser';
import Bookmarks from './pages/bookmarks';
import Remainders from './pages/remainders';


const App = () => {
  const { currentUser } = useAuth();
  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registerUser" element={<RegisterPage />} />
        <Route path="/dashboard" element={<ProtectedRoute><HomePage /></ProtectedRoute>}/>
        <Route path="/myDocs" element={<ProtectedRoute><MyDocs /></ProtectedRoute>}/>
        <Route path="/bookmarks" element={<ProtectedRoute><Bookmarks /></ProtectedRoute>} />
        <Route path="/remainders" element={<ProtectedRoute><Remainders /></ProtectedRoute>} />
        <Route
          path="/add-doc"
          element={
            <ProtectedRoute>
              <AddDocPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;

